<template>
    <div class="project">
        <h1>Samsung Centerstage</h1>
        <div class="topInfo">
            <p>
                When: 2014<br />
                Agency:
                <a href="http://www.potiondesign.com/">Barbarian Group</a> <br />
                Role: Programmer, Application Architecture
            </p>
        </div>
        <div class="content">
            <b-img class="media fade-in" src="@/assets/projects/centerstage/centerstage_img1.jpg" fluid />
            <p>
                Samsung CenterStage is an immersive, digital retail experience that utilizes interactive touchscreens to
                showcase the brand's home-appliance line in real-life sizes. The application runs at 8k resolution,
                utilizing special effects like motion blur and a seamless integration of interactive videos with UI
                content.
            </p>
            <p>
                We also used the development of this project as an assessment of libcinder's next OpenGL API, which was
                private at the time and called 'glNext'. The project proved the api worked well at making modern OpenGL
                fast and intuitive, while still allowing us to dig deep into the layers to utilize low level graphics
                and platform features.
            </p>
            <b-embed
                class="media"
                type="iframe"
                aspect="16by9"
                allowfullscreen
                src="https://player.vimeo.com/video/99379887"
            ></b-embed>
            <h2>The Menu</h2>
            <p>
                One of the more challenging / interesting components that I developed was the menu, which has a radial
                design and a cool, bursting animation when you open it.
            </p>
            <p>
                From a developer's perspective, there were a number of hurdles, such as how to animate the expanding
                circular edges and make sure they are nicely anti-aliased, and how to make it configurable as the number
                of buttons on each side is dynamic. I ended up drawing all of the radial segments in a fragment shader,
                where each segment is a quadrilateral that is smoothstepped based on a minimum and maximum radius. This
                made it easy and efficient to animate, the colors were dynamic, and was always perfectly anti-aliased.
            </p>
            <b-embed
                class="media"
                type="iframe"
                aspect="16by9"
                allowfullscreen
                src="https://player.vimeo.com/video/108612799"
            ></b-embed>
            <div class="content">
                More info on Barbarian Group's <a href="https://wearebarbarian.com/case-study/Samsung-Center-Stage">project page</a>.
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../assets/projects/style.scss";
@import "../../assets/common.scss";
</style>